body {
    margin: 0;
    font-family: "Yu Gothic UI", sans-serif;
}
.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
footer {
    margin-top: auto;
}